import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, scroller } from "react-scroll";
import { Link as ReactLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const navigate = useNavigate()
  const location =useLocation()
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const section = params.get("section");
    if (section) {
      scroller.scrollTo(section, {
        smooth: true,
        spy: true,
        offset: 120, // Adjust offset for fixed headers
        duration: 500,
      });
    }
  }, []);

  const handleNavigation = (target) => {
    if (location.pathname !== "/") {
      navigate(`/?section=${target}`);
    } else {
      scroller.scrollTo(target, {
        duration: 800,
        delay: 500,
        smooth: "easeInOutQuart",
        offset: -80,
      });
    }
  };

  const Menu = [
    { path: 'home', title: 'Home'},
    { path: 'services', title: 'Services'},
    { path: 'features', title: 'Features'},
    { path: 'blog', title: 'Blog'},
    { path: 'pricing', title: 'Pricing'},
    { path: 'contact', title: 'Contact'},
    { path: 'faq', title: 'FAQ', type: 'react'}
  ]

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <LogoIcon />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              Notify Me Back in Stock Alerts
            </h1>
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            {
              Menu.map((item) => {
                return (
                  <li className="semiBold font15 pointer">
                    {
                      item?.type === 'react' ?
                      <ReactLink 
                        to={`/${item?.path}`}
                        style={{ padding: "10px 15px" }}
                        className={location?.pathname === `/${item?.path}` && 'active'}
                      >
                        {item?.title}
                      </ReactLink> 
                      :
                      <Link
                        activeClass="active"
                        style={{ padding: "10px 15px" }}
                        to={item?.path}
                        spy={true}
                        smooth={true}
                        offset={-80}
                        onClick={() => (location.pathname !== '/') && handleNavigation(item?.path)}
                      >
                        {item?.title}
                      </Link>
                    }
                  </li>
                )
              })
            }
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
