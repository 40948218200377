import React from 'react';
import { useRoutes } from 'react-router-dom'

import Landing from '../screens/Landing';
import Faq from '../components/Pages/Faq/Index';

export default function Routes(){
    const routes = useRoutes([
        {
            path: "/",
            element: <Landing />
        },
        {
            path: "/faq",
            element: <Faq />
        }
    ]) 

    return routes;
}