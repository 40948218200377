import React, { useState, Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import PlusIcon from '../../../assets/img/faq/plus.png'
import MinusIcon from '../../../assets/img/faq/plus.png'

// images 
import FreeTrial1 from '../../../assets/img/faq/plan-page.png'
import EmailTemplate1 from '../../../assets/img/faq/email-template-1.png'
import EmailTemplate2 from '../../../assets/img/faq/email-template-2.png'
import MultipleProduct from '../../../assets/img/faq/multiple-products.png'
import MultipleVariant from '../../../assets/img/faq/multiple-variant.png'


export default function Faq({ type }) {
    const location = useLocation()
    const [faqs, setFaqs] = useState(type === 'page' ? defaultFaqs : defaultFaqs.slice(0, 4))

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname])

    const handleCollapse = (key) => {
        const data = faqs?.map((item) => { 
            if (item?.key === key) {
                item['active'] = item?.active ? false : true
            } else item['active'] = false
            return item
        })
        setFaqs(data)
    }

    const Labels = ({ label }) => {
        return (
            <Fragment >
                <div className='faq-label-header faq-border-bottom'>
                    <h4 className='faq-label-header-title'>{ label }</h4>
                </div>
            </Fragment>
        )
    }
  return (
    <div>
        <ul className='faq-section'>
            {
                faqs?.map((item, index) => {
                    return(
                        <Fragment key={`${item?.key}-${index}`}>
                        {
                            item?.key === 'label' ? 
                            <Labels
                                label={item?.content}
                            /> : 
                                <div className={`faq-content ${(faqs?.length -1) > index ? 'faq-border-bottom' : ''}  ${item?.active ? 'faq-active' : ''}`} onClick={() => handleCollapse(item?.key)}>
                                    <div className='faq-heading'>
                                        <h4 className='faq-heading-title'>{item?.content}</h4>
                                        <div className='faq-collapse-icon'>
                                            {
                                                item?.active ?
                                                <img src={MinusIcon} alt="minus-iocn" loading='lazy'/>
                                                : <img src={PlusIcon} alt="plus-iocn" loading='lazy'/>
                                            }
                                        </div>
                                    </div>
                                    { item?.active && 
                                    <Fragment>
                                        <p className='faq-heading-description' dangerouslySetInnerHTML={{ __html: item?.description }}></p> 

                                        {/* List  */}
                                        {
                                            item?.list && 
                                            <FaqSectionWrapper>
                                                <h5>{ item?.list?.title }</h5>
                                                <List>
                                                    {
                                                        item?.list?.options?.map((listItem) => {
                                                            return (
                                                                <li>{ listItem }</li>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </FaqSectionWrapper>
                                        }

                                        {/* images */}
                                        {
                                            item?.images && 
                                            <FaqSectionWrapper>
                                                <ImageGridContainer columns={1}>
                                                    {
                                                        (item?.images?.length > 0) &&
                                                            item?.images?.map((img) => {
                                                                return (
                                                                    <CardWrapper >
                                                                      <CardImage src={img?.src} alt={''} height="600px"/>
                                                                      {/* <CardContent>
                                                                        <CardTitle>{title}</CardTitle>
                                                                        <CardDescription>{description}</CardDescription>
                                                                      </CardContent> */}
                                                                    </CardWrapper>
                                                                )
                                                            })
                                                    }
                                                </ImageGridContainer>
                                            </FaqSectionWrapper>
                                        }
                                        {/* Note  */}
                                        {
                                            item?.note && 
                                            <FaqSectionWrapper>
                                                <p style={{color: 'red'}}>{item?.note}</p>
                                            </FaqSectionWrapper>
                                        }
                                    </Fragment>
                                    }
                                </div>
                        }
                        </Fragment>
                    )
                })
            }
        </ul>
    </div>
  )
}

const defaultFaqs = [
    {
        key: 'label',
        content: 'General question'
    },
    {
        key: 'free_trial',
        content: 'Do you offer a free trial?',
        description: 'Yes, you receive a 7 days free trial on install! During your trial period you have access to the Pro Plan and have 50 notifications at your disposal. Once the trial period is finished, or you exceed your quota, you will be asked to pick a paid plan which fits your usage. If you do not pick a plan, you will be placed on our free plan, which allows 50 notifications each month.',
        active: false,
        images: [
            { src: FreeTrial1 }
        ]
    },
    {
        key: 'stock_level',
        content: 'How long after I update my stock levels until notifications are sent?',
        description: 'Typically notification emails are sent out within a minute of stock levels being refilled in Shopify (typically in seconds).',
        active: false
    },
    {
        key: 'email_template',
        content: 'The email template does not fit our style, can it be altered?',
        description: 'Yes, we have customization settings in the dashboard to make changes to the template, you can also access the email template itself to make more in-depth changes.',
        active: false,
        images: [
            { src: EmailTemplate1 },
            { src: EmailTemplate2 }
        ]
    },
    {
        key: 'work_for_theme',
        content: 'I have a theme for my store, will Back in Stock work with it?',
        description: 'Back in Stock comes with an integration for most of the popular themes on Shopify, but if we do not have an integration for your installed theme, just send us an email at bisapp.23@gmail.com',
        active: false,
        list: {
            title: 'We support below themes',
            options: ['Dawn', 'Sense', 'Debut', 'Ride', 'Spotlight', 'Refresh', 'Studio', 'all free themes, etc'] 
        },
        note: "*If app doesn't support on your theme. please connect with us."
    },
    {
        key: 'mutiple_product_alert',
        content: 'Can customers sign up for multiple product alerts?',
        description: 'Yes, customers can subscribe to notifications for multiple out-of-stock products.',
        active: false,
        images: [
            { src: MultipleProduct }
        ]
    },
    {
        key: 'work_with_variants',
        content: 'Does this app work for variants (e.g., different sizes or colors)?',
        description: 'Yes, the app tracks stock levels for individual variants and sends alerts accordingly.',
        active: false,
        images: [
            { src: MultipleVariant }
        ]
    },
    {
        key: 'how_to_setup',
        content: 'How do I install and set up the app?',
        description: 'Welcome to our step-by-step setup guide for the Back in Stock Alert App! Install the app on your Shopify store <a href="https://www.youtube.com/embed/ZPn6hPyiIEw" target="_blank" rel="noopener noreferrer">watch tutorial</a>.',
        active: false
    }
]

const FaqSectionWrapper = styled.div`
    margin-top: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px; /* Adjust indentation */
`;

const ImageGridContainer = styled.div`
  display: grid;
  grid-template-columns:   repeat(${(props) => props?.columns || 2 }, 1fr); /* 2 images per row */
  gap: 30px;
  padding: 20px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr); /* 1 image per row on small screens */
  }
`;

const CardWrapper = styled.div`
  width: ${props => props.width || '100%' };
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  background: #fff;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: ${props => props.height || '100%' };
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 16px;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #333;
`;

const CardDescription = styled.p`
  margin-top: 8px;
  font-size: 14px;
  color: #666;
`;
