import React from 'react'
import styled from 'styled-components'

// components 
import TopNavbar from '../../Nav/TopNavbar'
import Header from '../../Sections/Header'
import Faq from './Faq'

export default function Index() {
  return (
    <div>
      <TopNavbar />
      {/* <Header /> */}
      <Wrapper 
        className="container" 
        // style={{borderTop: '1px solid #99999950'}}
      >
          <HeaderInfo>
            <h1 className="font40 extraBold">FAQ</h1>
          </HeaderInfo>
        <Faq type="page"/>
      </Wrapper>
    </div>
  )
}

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;